const splitEndorsementName = endorsement => {
  const num = endorsement.name.match(/[\d\.]+/);
  const letr = endorsement.name.match(/[a-zA-Z]+/g);
  return { letr: letr, num: num, endorsement: endorsement };
};

function sortF(ob1, ob2) {
  const letr1 = ob1.letr ? ob1.letr[0] : null;
  const letr2 = ob2.letr ? ob2.letr[0] : null;
  const num1 = ob1.num ? parseFloat(ob1.num[ob1.num.length - 1]) : null;
  const num2 = ob2.num ? parseFloat(ob2.num[ob2.num.length - 1]) : null;
  if (letr1 > letr2) {
    return 1;
  } else if (letr1 < letr2) {
    return -1;
  }

  // Else go to the 2nd item
  if (num1 < num2) {
    return -1;
  } else if (num1 > num2) {
    return 1;
  } else {
    // nothing to split them
    return 0;
  }
}

export const sortEndorsements = endorsements => {
  const splitEndorsements = endorsements.map(e => splitEndorsementName(e));
  return splitEndorsements.sort(sortF).map(w => w.endorsement);
};
